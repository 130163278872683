<template>
<div>
      <div class="wrapper">
        <header class="header">
                  <div class="header__outer inner">
                    <div class="header__inner" @click="goBack">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M20 8.93878V15.0612H11.9192V20L4 12L11.9192 4V8.93878H20Z" fill="url(#paint0_linear)"/>
                                      <defs>
                                      <linearGradient id="paint0_linear" x1="0.8" y1="12" x2="22.9333" y2="12" gradientUnits="userSpaceOnUse">
                                      <stop offset="0.0697675" stop-color="#A07DEA"/>
                                      <stop offset="1" stop-color="#5F4BAE"/>
                                      </linearGradient>
                                      </defs>
                                    </svg>

                      </div>
                      <h1 class="header__title">Your game</h1>
                      <div class="header__inner" @click="shareViaWebShare">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.6665 6.66684C17.3433 6.66686 18.0042 6.46084 18.5611 6.07616C19.118 5.69148 19.5446 5.14638 19.7842 4.51335C20.0238 3.88031 20.065 3.18935 19.9023 2.53233C19.7396 1.87531 19.3808 1.28338 18.8736 0.835245C18.3663 0.387114 17.7347 0.10402 17.0626 0.0236096C16.3905 -0.0568008 15.7099 0.0692828 15.1113 0.385094C14.5126 0.700905 14.0243 1.19148 13.7112 1.79157C13.3981 2.39167 13.2751 3.07286 13.3586 3.74454L5.6473 7.6002C5.17644 7.14613 4.58238 6.84064 3.93914 6.72178C3.2959 6.60292 2.6319 6.67594 2.02986 6.93174C1.42782 7.18754 0.914344 7.61482 0.553401 8.16036C0.192459 8.7059 0 9.34557 0 9.9997C0 10.6538 0.192459 11.2935 0.553401 11.839C0.914344 12.3846 1.42782 12.8119 2.02986 13.0677C2.6319 13.3235 3.2959 13.3965 3.93914 13.2776C4.58238 13.1588 5.17644 12.8533 5.6473 12.3992L13.3586 16.2549C13.2614 17.0352 13.4431 17.8247 13.8717 18.4839C14.3003 19.1432 14.9481 19.6297 15.7007 19.8575C16.4533 20.0853 17.2622 20.0397 17.9845 19.7288C18.7067 19.4179 19.2958 18.8617 19.6476 18.1585C19.9994 17.4552 20.0913 16.6503 19.907 15.8858C19.7228 15.1214 19.2742 14.4468 18.6406 13.9811C18.007 13.5154 17.2292 13.2887 16.4446 13.341C15.66 13.3933 14.9192 13.7213 14.3531 14.267L6.64177 10.4114C6.67537 10.1383 6.67537 9.86219 6.64177 9.58914L14.3531 5.73348C14.9509 6.31128 15.7665 6.66684 16.6665 6.66684Z" fill="url(#paint0_linear)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear" x1="-4" y1="10.0002" x2="23.6667" y2="10.0002" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.0697675" stop-color="#A07DEA"/>
                                        <stop offset="1" stop-color="#5F4BAE"/>
                                        </linearGradient>
                                        </defs>
                                  </svg>

                      </div>
                  </div>
              </header>
              <div class="counter_block">
                <p>Time</p>
                <button class="counter">00:00:00</button>
              </div>


              <div class="counter_block">
                <button class="retry-button">PLAY</button>
                <button class="retry-button" v-show="newGame" @click="goBack" >NEW GAME</button>
              </div>
              <div id="Puzzle" class="def"></div>


        <p class="game-explanation">
          <span class="important">How to play.</span> Move tiles in the grid to order them right. To move a tile, you can tap on it or swipe.
        </p>

      </div>

    <div class="info_success" v-show="info">
        <h3 class="infotitle_success">Well done!</h3>
        <span class="infoclose"  @click="closeInfo"></span>
        <p><b>Your score: {{minutes}} min {{seconds}} sec</b></p>
        <p>Share your result with your friend!</p>
        <div class="social_buttons">

          <a @click="shareViaWebShare">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.8547 5.05156C17.8906 3.08281 15.275 2 12.4953 2C6.75781 2 2.08906 6.66875 2.08906 12.4062C2.08906 14.2391 2.56719 16.0297 3.47656 17.6094L2 23L7.51719 21.5516C9.03594 22.3813 10.7469 22.8172 12.4906 22.8172H12.4953C18.2281 22.8172 23 18.1484 23 12.4109C23 9.63125 21.8188 7.02031 19.8547 5.05156ZM12.4953 21.0641C10.9391 21.0641 9.41562 20.6469 8.08906 19.8594L7.775 19.6719L4.50313 20.5297L5.375 17.3375L5.16875 17.0094C4.30156 15.6313 3.84688 14.0422 3.84688 12.4062C3.84688 7.63906 7.72812 3.75781 12.5 3.75781C14.8109 3.75781 16.9812 4.65781 18.6125 6.29375C20.2437 7.92969 21.2469 10.1 21.2422 12.4109C21.2422 17.1828 17.2625 21.0641 12.4953 21.0641ZM17.2391 14.5859C16.9813 14.4547 15.7016 13.8266 15.4625 13.7422C15.2234 13.6531 15.05 13.6109 14.8766 13.8734C14.7031 14.1359 14.2063 14.7172 14.0516 14.8953C13.9016 15.0688 13.7469 15.0922 13.4891 14.9609C11.9609 14.1969 10.9578 13.5969 9.95 11.8672C9.68281 11.4078 10.2172 11.4406 10.7141 10.4469C10.7984 10.2734 10.7562 10.1234 10.6906 9.99219C10.625 9.86094 10.1047 8.58125 9.88906 8.06094C9.67813 7.55469 9.4625 7.625 9.30313 7.61563C9.15313 7.60625 8.97969 7.60625 8.80625 7.60625C8.63281 7.60625 8.35156 7.67188 8.1125 7.92969C7.87344 8.19219 7.20312 8.82031 7.20312 10.1C7.20312 11.3797 8.13594 12.6172 8.2625 12.7906C8.39375 12.9641 10.0953 15.5891 12.7062 16.7188C14.3562 17.4312 15.0031 17.4922 15.8281 17.3703C16.3297 17.2953 17.3656 16.7422 17.5812 16.1328C17.7969 15.5234 17.7969 15.0031 17.7313 14.8953C17.6703 14.7781 17.4969 14.7125 17.2391 14.5859Z" fill="url(#paint0_linear)"/>
                  <defs>
                  <linearGradient id="paint0_linear" x1="-2.2" y1="12.5" x2="26.85" y2="12.5" gradientUnits="userSpaceOnUse">
                  <stop offset="0.0697675" stop-color="#A07DEA"/>
                  <stop offset="1" stop-color="#5F4BAE"/>
                  </linearGradient>
                  </defs>
              </svg>
          </a>

          <a @click="shareViaWebShare">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.0032 1.5C11.8496 1.5 10 3.0615 10 6.6195V9.75H6V13.5H10V22.5H14V13.5H17.2L18 9.75H14V7.254C14 5.91375 14.4664 5.25 15.808 5.25H18V1.65375C17.6208 1.60575 16.516 1.5 15.0032 1.5Z" fill="url(#paint0_linear)"/>
                  <defs>
                  <linearGradient id="paint0_linear" x1="3.6" y1="12" x2="20.2" y2="12" gradientUnits="userSpaceOnUse">
                  <stop offset="0.0697675" stop-color="#A07DEA"/>
                  <stop offset="1" stop-color="#5F4BAE"/>
                  </linearGradient>
                  </defs>
              </svg>
           </a>

          <a @click="shareViaWebShare">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.4453 3.00195C21.186 3.02095 20.9314 3.08224 20.6916 3.18341H20.6883C20.4581 3.27536 19.3634 3.7392 17.6992 4.44228L11.7354 6.97222C7.45611 8.78687 3.24949 10.5739 3.24949 10.5739L3.29958 10.5543C3.29958 10.5543 3.00955 10.6504 2.70659 10.8595C2.51943 10.9795 2.35838 11.1364 2.23318 11.3209C2.08453 11.5406 1.96496 11.8767 2.0094 12.2241C2.0821 12.8117 2.46019 13.164 2.73164 13.3585C3.00632 13.5554 3.26807 13.6474 3.26807 13.6474H3.27453L7.2194 14.986C7.39633 15.5581 8.42152 18.953 8.66792 19.735C8.81334 20.2021 8.95472 20.4943 9.13165 20.7172C9.21728 20.8311 9.31746 20.9264 9.43783 21.0028C9.50041 21.0395 9.56713 21.0685 9.63657 21.0891L9.59618 21.0793C9.60829 21.0826 9.61799 21.0924 9.62688 21.0956C9.65919 21.1046 9.681 21.1078 9.72221 21.1143C10.3467 21.3047 10.8484 20.9141 10.8484 20.9141L10.8767 20.8914L13.2058 18.7553L17.1094 21.7718L17.1983 21.8101C18.0118 22.1698 18.8359 21.9696 19.2713 21.6164C19.71 21.2608 19.8805 20.8059 19.8805 20.8059L19.9087 20.7327L22.9254 5.16651C23.011 4.78242 23.0328 4.42275 22.9383 4.07365C22.8408 3.72034 22.6158 3.41647 22.3073 3.22166C22.0483 3.06307 21.748 2.98652 21.4453 3.00195ZM21.3637 4.67013C21.3605 4.72139 21.3702 4.7157 21.3476 4.81416V4.82311L18.3592 20.2273C18.3463 20.2493 18.3245 20.2973 18.2647 20.3453C18.2017 20.3958 18.1516 20.4275 17.889 20.3226L13.1145 16.6355L10.2304 19.2834L10.8363 15.3855L18.6371 8.06182C18.9587 7.76074 18.8512 7.69727 18.8512 7.69727C18.8738 7.32782 18.3657 7.58904 18.3657 7.58904L8.52897 13.7271L8.52574 13.7109L3.81096 12.1118V12.1086L3.79885 12.1061C3.80711 12.1034 3.8152 12.1001 3.82308 12.0964L3.84893 12.0834L3.87398 12.0744C3.87398 12.0744 8.08383 10.2874 12.3632 8.47277C14.5057 7.56381 16.6643 6.64835 18.3245 5.94201C19.9847 5.23975 21.2118 4.72465 21.2813 4.69698C21.3476 4.67094 21.3161 4.67094 21.3637 4.67094V4.67013Z" fill="url(#paint0_linear)"/>
                  <defs>
                  <linearGradient id="paint0_linear" x1="-2.2" y1="12.5" x2="26.85" y2="12.5" gradientUnits="userSpaceOnUse">
                  <stop offset="0.0697675" stop-color="#A07DEA"/>
                  <stop offset="1" stop-color="#5F4BAE"/>
                  </linearGradient>
                  </defs>
              </svg>
             </a>

            <a @click="shareViaWebShare">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23 3.01009C22.0424 3.68768 20.9821 4.20594 19.86 4.54489C19.2577 3.85023 18.4573 3.35787 17.567 3.1344C16.6767 2.91094 15.7395 2.96715 14.8821 3.29543C14.0247 3.62372 13.2884 4.20824 12.773 4.96994C12.2575 5.73163 11.9877 6.63376 12 7.55431V8.55744C10.2426 8.60315 8.50127 8.21218 6.93101 7.41933C5.36074 6.62648 4.01032 5.45638 3 4.01323C3 4.01323 -1 13.0415 8 17.054C5.94053 18.4564 3.48716 19.1595 1 19.0603C10 24.076 21 19.0603 21 7.52421C20.9991 7.24479 20.9723 6.96606 20.92 6.69161C21.9406 5.68194 22.6608 4.40717 23 3.01009V3.01009Z" stroke="url(#paint0_linear)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <defs>
                  <linearGradient id="paint0_linear" x1="-3.4" y1="12" x2="27.0333" y2="12" gradientUnits="userSpaceOnUse">
                  <stop offset="0.0697675" stop-color="#A07DEA"/>
                  <stop offset="1" stop-color="#5F4BAE"/>
                  </linearGradient>
                  </defs>
              </svg>
             </a>

        </div>
    </div>
    <div class="back" v-show="info"></div>

    <Adsense
        ins-style="display:inline-block;width:320px;height:50px"
        data-ad-client="ca-pub-1807289039126878"
        data-ad-slot="7973698520"
        >
    </Adsense>
    
</div>
</template>

<script>

import axios from 'axios'
       

export default {
  name:'PlayGame',
  data(){
    return{
        		src:"https://www.jesperpus.games/uploads/findjesper/bcfc3776337b44e1a2298c283b743939.jpg",
         		profileIcon:null,
                canPlay:true,
                notificationStyles:{
                        mainClass:null,
                        mainActiveClass:null,
                        isBackground:true
                },
                showNotificationVal:null,
                level:null,
				image:null,
				init:0,
				bestRecord :9999999,
				clockTimer:null,
				startDate:null,
				b:null,
			  profile:{
				  nickname:null,
				  email:null
			  },
      nicknameFromInput:null,
      info:false,
      minutes:null,
      seconds:null,
      newGame:false,
      showSharedButtom:false,


    };
  },
  created(){
    //this.getProfile();
  },
  mounted(){
     this.initGame();
  },
  destroyed(){
    clearTimeout(this.clockTimer);
  },
  computed:{
        webShareApiSupported() {
            return navigator.share
        }
    },
   methods:{
      showInfo(){
            this.info = true;
        },
      closeInfo(){
            this.info = false;
      },
	   initGame(){

       var auto_size = false;
       if (/mobile/i.test(navigator.userAgent) && !/ipad|tablet/i.test(navigator.userAgent)) {
              auto_size = true;
        }

       var that = this;

	   		var config = {
                  img_puzzle: "https://puzzle.jesperpus.games/"+this.$route.query.img_url,
                  duration: 200,
                  width: 500,
                  height: 500,
                  gap: false,
                  isSwipe: false,
                  auto_size: auto_size,
                  bgColor: "#000",
                  bgOpacity: 1,
                  imgBgOpacity: 0.3,
                  tilesH: 4,
                  tilesV: 4,
                  mixed: false,
				          numbering: false,
				          shuffleNum: 0,
                  onCompleted: function () {
                      that.init = 0;
                      clearTimeout(this.clockTimer);
                      that.timeText = $('.counter').html();
                      that.showInfo();
                      that.newGame = true;
                      $(".sh_puzzle").css("filter","brightness(0.5)");
                      $(".sh_puzzle").css("pointer-events","none");
                      this.showSharedButtom =true;
                  },
                  onStartPlay: function () {
                      that.init = 1;
                      that.startDate = new Date();
                      that.startTIME();
                  }
				};
			
				 $(document).bind('keydown', function(e) {
                  if((e.which === 13 || e.which === 32) && init === 0){
                    that.start();
                  }
                });
				

        $('#Puzzle.def').shufflepuzzle(config);
        
        $('.retry-button').bind('click', function () {
			      that.start();
		    });

	   },
		getProfile(){

            this.$store.dispatch("getProfile")
						.then(response =>{
								this.profile.nickname =response.data.nickname;
								this.profile.email =response.data.email;
								document.getElementById("loader").style="display:none";
								this.initGame();

                        }) .catch(error => {
              });
        },
    goBack(){
						   this.$router.push({ path: 'choose' })
            },
    shareViaWebShare() {

            this.$store.dispatch("addPuzzleShareStats",this.$route.query.img_url)
						.then(response =>{
								
console.log(response.data);
                        });

                var endText="";        

                if(this.minutes!=null)
                {
                    endText = ' and my score was '+this.minutes+' min '+this.seconds+' sec.'
                }

                navigator.share({
                    title: 'Jesperpus cat puzzle',
                    text: 'I played interesting game https://play.google.com/store/apps/details?id=com.jesper.jesperpuscatpuzzle'+endText
                });

        },
        start(){
            $(".sh_puzzle").css("filter","brightness(1)");
            $(".sh_puzzle").css("pointer-events","all");
            $(".retry-button").css("display","none");
            $('#Puzzle.def').shufflepuzzle['def']({shuffleNum: 18});

        },
        startTIME() {
                var that = this;
                var thisDate = new Date();
                var t = thisDate.getTime() - this.startDate.getTime();
                this.b = t;
                if (this.init===1) {
                  $('.counter').html(this.convert(t));
                  this.clockTimer = setTimeout(function () {
                    that.startTIME();
                  }, 10);
                }
          },
          convert(t) {
            var ms,t,s,m,h;

                    ms = t%1000; t-=ms; ms=Math.floor(ms/10);
                    t = Math.floor (t/1000);
                    s = t%60; t-=s;
                    t = Math.floor (t/60);
                    m = t%60; t-=m;
                    t = Math.floor (t/60);
                    h = t%60;
                    if (h<10) h='0'+h;
                    if (m<10) m='0'+m;
                    if (s<10) s='0'+s;
                    if (ms<10) ms='0'+ms;

                    this.minutes = m;
                    this.seconds = s;

                    return h + ':' + m + ':' + s;
		        }        
   }
  
}

</script>